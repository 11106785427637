<script>
import { mapState } from "vuex";
import axios from "axios";

// import _ from "lodash";
// import { ElMessage } from "element-plus";
import ObjectID from "bson-objectid";

export default {
  name: "lottieResource",
  components: {},
  props: {},
  data() {
    return {
      list: [],
      pefix: "https://resources.laihua.com/",
    };
  },
  mounted() {
    this.lodaData();
  },
  computed: {
    ...mapState({ msg: (state) => state.msg, editor: (state) => state.editor }),
  },
  methods: {
    lodaData() {
      let that = this;
      let url =
        "https://www.laihua.com/api/common/material/findCharacterActionByName?fPage=50&sOfPage=1&pIndex=1&name=%E8%AE%B2%E8%A7%A3&category=100485&style=78";

      axios.get(url).then(function (response) {
        console.log(response.data.data);
        that.list = response.data.data;
      });
    },
    select(item) {
      //console.log(item.url);
      var sprite1 = {
        name: "",
        type: "lottie",
        path: this.pefix + item.url,
        preview: this.pefix + item.url,
        width: 480,
        height: 720,
        duration: 3,
        id: ObjectID().toHexString(),
        left: 0,
        top: 0,
        start: 0,
        clip: 0,
        muted: false,
        active: false,
      };
      //console.log(obj);

      //
      this.editor.currentSence.sprites.push(sprite1);
    },
  },
};
</script>

<template>
  <div class="js_cont" v-for="item of list" :key="item.id">
    <img :src="pefix + item.thumbnailUrl" class="img1" @click="select(item)" />
  </div>
</template>

<style scoped>
.img1 {
  width: 88px;
  height: 160px;
  cursor: pointer;
  margin: 5px;
  background-color: #e0e0e0;
}
.js_cont {
  display: inline-block;
}
</style>
