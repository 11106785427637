<script>
import { mapState } from "vuex";
import axios from "axios";

// import _ from "lodash";
// import { ElMessage } from "element-plus";
// import ObjectID from "bson-objectid";

export default {
  name: "musicResource",
  components: {},
  props: {},
  data() {
    return {
      list: [],
      pefix: "https://resources.laihua.com/",
    };
  },
  mounted() {
    this.lodaData();
  },
  computed: {
    ...mapState({ msg: (state) => state.msg, editor: (state) => state.editor }),
  },
  methods: {
    lodaData() {
      let that = this;
      let url =
        "https://www.laihua.com/api/common/material?type=5&fPage=100&sOfPage=100&pIndex=1&category=141206&style=78&payType=0";

      axios.get(url).then(function (response) {
        console.log(response.data.data);
        that.list = response.data.data;
      });
    },
    select(item) {
      //console.log(item.url);
      // var sprite1 = {
      //   name: "",
      //   type: "image",
      //   path: this.pefix + item.url,
      //   preview: this.pefix + item.url,
      //   width: 400,
      //   height: 400,
      //   duration: 3,
      //   id: ObjectID().toHexString(),
      //   left: 0,
      //   top: 0,
      //   start: 0,
      //   clip: 0,
      //   muted: false,
      //   active: false,
      // };
      // console.log(sprite1);

      //
      //this.editor.currentSence.sprites.push(sprite1);
      //this.editor.currentSence.backgroundImage = this.pefix + item.url;
      this.editor.meta.bgMusicName = item.title;
      this.editor.meta.bgMusic = this.pefix + item.url;
    },
  },
};
</script>

<template>
  <div class="js_cont" v-for="item of list" :key="item.id">
    <div>
      <el-button
        :type="editor.meta.bgMusic == pefix + item.url ? 'primary' : 'info'"
        @click="select(item)"
        >{{ item.title }}</el-button
      >
    </div>
    <audio controls :src="pefix + item.url"></audio>
  </div>
</template>

<style scoped>
.img1 {
  width: 200px;
  height: 90px;
  cursor: pointer;
}
.js_cont {
  display: inline-block;
  padding-top: 5px;
  margin: 10px;
  background-color: #f0f0f0;
}
</style>
